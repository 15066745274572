import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { X } from 'react-feather';
import { isMobile } from 'react-device-detect';

import * as S from './styles';
import { NavState } from 'store/ducks/nav/types';
import { Bold } from 'styles/Commons';
import FaqService from 'services/FaqService';

interface StateProps {
	nav: NavState,
}

const Help = () => {
	const select = (state: StateProps) => state.nav;
	const { isShowMobileOptions, hasFixedFooterContent } = useSelector(select);
	const whatsappNumber = process.env.REACT_APP_SUPPORT_WHATSAPP || null;

	const [isOpen, setIsOpen] = useState(false);
	const [links, setLinks] = useState<any>([]);

	// useEffect(() => {
	// 	getLinks();
	// }, []);

	useEffect(() => {
		if (isMobile) {
			document.body.style.overflow = (isOpen ? 'hidden' : 'initial');
		}
	}, [isOpen]);

	const getLinks = () => {
		FaqService.getArticlesMostAccessed()
			.then(({ data }) => setLinks(data.mostAccessedParticipant.slice(0, 3)))
	}

	return (
		<>
			{/*<S.HelpToggler bottom={isShowMobileOptions || hasFixedFooterContent} onClick={() => setIsOpen(true)}>*/}
			{/*	<S.HelpText>Precisa de <Bold>ajuda?</Bold></S.HelpText>*/}
			{/*	<S.HelpButton>*/}
			{/*		?*/}
			{/*	</S.HelpButton>*/}
			{/*</S.HelpToggler>*/}

			<S.HelpBox isOpen={isOpen}>
				<S.HelpBoxHeader>
					<S.HelpBoxTitle>Como podemos ajudar você?</S.HelpBoxTitle>
					<S.HelpBoxClose onClick={() => setIsOpen(false)}>
						<X size="20px" />
					</S.HelpBoxClose>
				</S.HelpBoxHeader>

				{links.map((link: any) => <S.HelpBoxLink key={link.id} href={`${process.env.REACT_APP_FAQ_URL}/artigo/p/${link.url}`} target="_blank">{link.title}</S.HelpBoxLink>)}

				<S.HelpBoxButtonLink href={process.env.REACT_APP_FAQ_URL} target="_blank">Central de Ajuda</S.HelpBoxButtonLink>
				{whatsappNumber && <S.HelpBoxButtonLinkOutline href={`https://api.whatsapp.com/send?phone=${whatsappNumber}`} target="_blank">Falar com atendente</S.HelpBoxButtonLinkOutline>}
			</S.HelpBox>

			{isMobile && <S.ScreenFade isOpen={isOpen} onClick={() => setIsOpen(false)} />}
		</>
	)
}

export default Help;
