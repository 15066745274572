import React from 'react';
import { Calendar, Clock, MapPin } from 'react-feather';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

import { dateGlobalPattern } from 'utils/dateUtils';
import { Event } from 'store/ducks/home/types';
import { IconWrapper } from 'styles';
import { StyleProps } from './types';
import * as S from './styles';
import Variables from 'styles/Variables';

type Props = StyleProps & Event;

const EventCard = ({
	thumbnail,
	name,
	startDate,
	locationName,
	locationType,
	seatsMapActived,
	url,
	short,
	main,
	hasFacebookPixel,
}: Props) => (
	<S.Card to={`/${url}`} short={short} main={main} data-test="eventCard">
		<S.Image thumbnail={thumbnail} short={short} main={main} />

		<S.InfoWrapper>

			<S.NameAndAddress>
				<S.EventTitle title={name}>{name}</S.EventTitle>

				<S.EventAddress>
					<IconWrapper height={15} width={15}>
						<MapPin size={15} color={Variables.colors.main}/>
					</IconWrapper>
					<S.Text>{locationType === 'PHYSICAL' ? locationName : 'Evento virtual'}</S.Text>
				</S.EventAddress>
			</S.NameAndAddress>
		</S.InfoWrapper>
	</S.Card>
);

export default EventCard;
