import React from 'react';

import {Social} from 'components/home';
import {Seo} from 'components/standalone';

import HomeMainEvents from './HomeMainEvents';
import HomeFeaturedEvents from './HomeFeaturedEvents';

const HomePage = () => {

	return (
		<>
			<Seo />
			<HomeMainEvents />

			<HomeFeaturedEvents />

			<Social />

			{/*<About />*/}

		</>
	);
};

export default HomePage;
