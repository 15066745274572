import React from 'react';

import { Container } from 'styles';
import * as S from './styles';

const Footer = () => {
	const producerSupportNumber = process.env.REACT_APP_PRODUCER_SUPPORT_WHATSAPP || null;
	const supportNumber = process.env.REACT_APP_SUPPORT_WHATSAPP || null;

    const getSocialLinks = () => {
        return (
            <S.FooterSocialGroup>
                <S.FooterSocialItem
                    href={process.env.REACT_APP_SOCIAL_FACEBOOK}
                    target="_blank"
                    rel="noreferrer noopener"
                    icon={`${process.env.PUBLIC_URL}/icons/facebook.svg`} />

                <S.FooterSocialItem
                    href={process.env.REACT_APP_SOCIAL_INSTAGRAM}
                    target="_blank"
                    rel="noreferrer noopener"
                    icon={`${process.env.PUBLIC_URL}/icons/instagram.svg`} />

                {/*<S.FooterSocialItem*/}
                {/*    href={process.env.REACT_APP_SOCIAL_TWITTER}*/}
                {/*    target="_blank"*/}
                {/*    rel="noreferrer noopener"*/}
                {/*    icon={`${process.env.PUBLIC_URL}/icons/twitter.svg`} />*/}
            </S.FooterSocialGroup>
        )
    }

    return (
        <S.FooterWrapper>
            <Container>
				<>
					<S.FooterContent>
						<S.FooterGroup>
							<S.FooterLogo src={`${process.env.REACT_APP_BUCKET_URL}/brand/logo-full-mono.svg`} />

							<S.FooterSocialWeb>
								{getSocialLinks()}
							</S.FooterSocialWeb>
						</S.FooterGroup>

						<S.FooterGroup>
							<S.FooterTitle>{process.env.REACT_APP_TENANT_NAME}</S.FooterTitle>
							{/*<S.FooterLink to="/">Quem somos</S.FooterLink>*/}
							<S.FooterLinkExternal href={`${process.env.REACT_APP_FAQ_URL}/politicas-de-privacidade`} target="_blank">Política de privacidade</S.FooterLinkExternal>
							<S.FooterLinkExternal href={`${process.env.REACT_APP_FAQ_URL}/termos-de-uso`} target="_blank">Termos de uso</S.FooterLinkExternal>
						</S.FooterGroup>

						{/*<S.FooterGroup>*/}
						{/*	<S.FooterTitle>Produtores</S.FooterTitle>*/}
						{/*	<S.FooterLinkExternal href={`${process.env.REACT_APP_PRODUCER_URL}/evento/novo`}>Criar evento</S.FooterLinkExternal>*/}
						{/*	{producerSupportNumber && <S.FooterLinkExternal href={`https://api.whatsapp.com/send?phone=${producerSupportNumber}`} target="_blank">Falar com consultor</S.FooterLinkExternal>}*/}
						{/*</S.FooterGroup>*/}

						<S.FooterGroup>
							<S.FooterTitle>Ajuda</S.FooterTitle>
							{/*<S.FooterLinkExternal href={`${process.env.REACT_APP_FAQ_URL}`} target="_blank">Central de ajuda</S.FooterLinkExternal>*/}
							{supportNumber && <S.FooterLinkExternal href={`https://api.whatsapp.com/send?phone=${supportNumber}`} target="_blank">Falar com atendimento</S.FooterLinkExternal>}
						</S.FooterGroup>

						<S.FooterSocialMobile>
							{getSocialLinks()}
						</S.FooterSocialMobile>

					</S.FooterContent>
					<S.FooterCompany>{process.env.REACT_APP_COMPANY}</S.FooterCompany>
				</>

            </Container>
        </S.FooterWrapper>
    );
}

export default Footer;
