import styled from 'styled-components';
import { breakAt, BreakpointsSizes } from 'styles/Breakpoint';
import Variables from 'styles/Variables';
import { StyleProps } from './types';

export const SocialWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;

	&:before{
		content: '';
		position: absolute;
		height: 5px;
		width: 100%;
		background: ${Variables.colors.second};
		top: 50%;
		transform: translateY(-50%);
	}
`;

export const SocialItem = styled.a`
	height: 60px;
    width: 60px;
    border-radius: 50%;
    z-index: 1;
    margin: 0 10px;
    border: 5px solid #f2f2f2;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	background-image: linear-gradient(47deg, ${Variables.colors.third} 13%, ${Variables.colors.third} 13%, ${Variables.colors.second} 49%, ${Variables.colors.second} 49%, ${Variables.colors.main} 97%, ${Variables.colors.main} 136%);

	${breakAt(BreakpointsSizes.md)} {
		width: 90px;
		height: 90px;
		padding: 20px;
	}
`;

export const SocialItemImage = styled.div`
	height: 100%;
	width: 100%;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(${({ image }: StyleProps) => image || null});
`;
